import firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyDGqu7N4psM4vLq3vZCI54MB_IzDOpYTAU",
//   authDomain: "slack-clone-7a283.firebaseapp.com",
//   projectId: "slack-clone-7a283",
//   storageBucket: "slack-clone-7a283.appspot.com",
//   messagingSenderId: "1000797692699",
//   appId: "1:1000797692699:web:df8c0b3b747f5540a5e9be",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDntz62DHruZjCcJ2g8U1iCQwPgVZLWn6U",
  authDomain: "rooms-lakki-3bf8a.firebaseapp.com",
  projectId: "rooms-lakki-3bf8a",
  storageBucket: "rooms-lakki-3bf8a.appspot.com",
  messagingSenderId: "595555412794",
  appId: "1:595555412794:web:68dd4cdff49add94637e03",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider, db };
